import { createI18n } from 'vue-i18n'

import messages from '@/i18n/lang'

const instance = createI18n({
  legacy: false,
  warnHtmlMessage: false,
  locale: import.meta.env.VITE_DEFAULT_LOCALE,
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  messages,
})

export const i18n = instance.global

export default instance
