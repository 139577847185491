export const de = {
  trees: 'Bäume',
  carbon: 'CO2',
  plastic: 'Plastik',
  kelp: 'Seetang',
  PublicHeader: {
    links: {
      business: 'Business',
      personal: 'Privat',
      about: 'Über Uns',
    },
    buttons: {
      login: 'Einloggen',
      register: 'Registrieren',
    },
  },
  PublicFooter: {
    climate_hero: {
      title: 'Werde zum Klimahelden!',
      cta: 'Mach den ersten Schritt',
    },
    copyright: 'Copyright © {currentYear} Greenspark. All rights reserved.',
    menus: {
      company: 'Firma',
      contact: 'Kontakt',
      legal: 'Rechtliches',
    },
    links: {
      about: 'Über uns',
      projects: 'Projekte',
      case_studies: 'Erfolgsgeschichten',
      journal: 'Journal',
      book_demo: 'Demo buchen',
      email: 'Chatte mit uns',
      terms_and_conditions: 'AGB',
      privacy_policy: 'Datenschutz',
    },
  },
  PublicHero: {
    description_company:
      'Wir sind bei Greenspark dabei, um sicher zu gehen, dass {name} eine positive Auswirkung auf unser Klima hat. Hier kannst du unseren Impact verfolgen!',
    description_personal:
      'Ich bin bei Greenspark dabei um eine positive auswirkung auf unser Klima zu haben. Hier kannst du meinen Impact verfolgen!',
    description:
      'Wir sind bei Greenspark dabei, um sicher zu gehen, dass wir eine positive Auswirkung auf unser Klima hat. Hier kannst du unseren Impact verfolgen!',
    member_since: 'Mitglied Seit',
    website: 'Unsere Webseite',
  },
  PublicTopStatCardList: {
    title: 'Unser positiver Impact',
    new_member: {
      title: 'Neues mitglied!',
      description:
        'Wir <strong>haben gerade unsere</strong> Reise mit Greenspark begonnen und es wurden noch keine Projekte finanziert - noch nicht!',
    },
    check_below: 'Schau unten nach, um einige Projekte zu sehen, die wir unterstützen werden:',
  },
  PublicTopStatCard: {
    trees: 'Baum gepflanzt | Bäume gepflanzt',
    plastic: 'Plastikflasche gerettet| Plastikflaschen gerettet',
    carbon: 'Tonne CO2-Ausgleich | Tonnen CO2 kompensiert',
    kelp: 'Seetang gepflanzt',
    months_earth_positive: 'Klima-positiv',
    months_amount: '{count} Monat | {count} Monate',
    tooltip: {
      transferredImpact: {
        carbon:
          '<p><strong>Übertragener Impact:</strong> {amount} Tonne CO2 wurde von einer vorherigen Quelle übertragen. Überprüfe den öffentlichen Zahlungsregister, um alle Rechnungen zu sehen.</p> | <p><strong>Übertragener Impact:</strong> {amount} Tonnen CO2 wurden von einer vorherigen Quelle übertragen. Überprüfe den öffentlichen Zahlungsregister, um alle Rechnungen zu sehen.</p>',
        plastic:
          '<p><strong>Übertragener Impact:</strong> {amount} Plastikflasche wurde von einer vorherigen Quelle übertragen. Überprüfe den öffentlichen Zahlungsregister, um alle Rechnungen zu sehen.</p> | <p><strong>Übertragener Impact:</strong>{amount} Plastikflaschen wurden von einer vorherigen Quelle übertragen. Überprüfe den öffentlichen Zahlungsregister, um alle Rechnungen zu sehen.</p>',
        trees:
          '<p><strong>Übertragener Impact:</strong> {amount} Baum wurde von einer vorherigen Quelle übertragen. Überprüfe den öffentlichen Zahlungsregister, um alle Rechnungen zu sehen.</p> | <p><strong>Übertragener Impact:</strong> {amount} Bäume wurde von einer vorherigen Quelle übertragen. Überprüfe den öffentlichen Zahlungsregister, um alle Rechnungen zu sehen.</p>',
        kelp: '<p><strong>Übertragener Impact:</strong> {amount} Seetang wurde von einer vorherigen Quelle übertragen. Überprüfe den öffentlichen Zahlungsregister, um alle Rechnungen zu sehen.</p>',
      },
      partnerImpact: {
        carbon:
          '<strong>Partner Impact:</strong> {amount} Tonne CO2 stammt aus Partnerschaften. | <strong>Partner Impact:</strong> {amount} Tonnen CO2 stammen aus Partnerschaften.',
        plastic:
          '<strong>Partner Impact:</strong> {amount} Plastikflasche stammt aus Partnerschaften. | <strong>Partner Impact:</strong> {amount} Plastikflaschen stammen aus Partnerschaften',
        trees:
          '<strong>Partner Impact:</strong> {amount} Baum wurde durch Partnerschaften gepflanzt. | <strong>Partner Impact:</strong> {amount} Bäume wurden durch Partnerschaften gepflanzt.',
        kelp: '<strong>Partner Impact:</strong> {amount} Seetang wurden durch Partnerschaften gepflanzt.',
      },
    },
  },
  PublicComparisonCardRow: {
    carbon: 'Unsere CO2-Kompensationen entsprechen',
    plastic: 'Unser eingesammeltes Plastik entspricht',
    trees: 'Unsere gepflanzten Bäume entsprechen',
    kelp: 'Unsere gepflanzten Seetang entsprechen',
  },
  PublicComparisonCard: {
    trees: {
      areaPlanted: {
        desc: 'Hektar Bepflanzte Waldfläche (2,500 Baüme/Hektar).',
        link: 'Unsere Bäume werden mit einer Dichte von 2.500 Bäumen/Hektar gepflanzt.',
      },
      carbonSequestered: {
        desc: 'Potentielle CO2-kompensierung pro Jahr.', // TODO: kg or tonne?
        link: 'Im Durchschnitt bindet ein Hektar ausgewachsener Bäume 23 Tonnen CO2 im Jahr.',
      },
      footballPitches: {
        desc: 'Fußballplätze mit Bäumen bepflanzt.',
        link: 'Der durchschnittliche Fußballplatz ist 0,71 Hektar groß und unsere Bäume werden mit einer Dichte von 2.500 Bäumen/Hektar gepflanzt.',
      },
    },
    plastic: {
      straws: {
        desc: 'Plastikstrohhalme von der Mülldeponie entfernt.',
        link: 'Ein durchschnittlicher Strohhalm wiegt 0,5 g.',
      },
      coffeeCups: {
        desc: 'Einweg-Kaffeetassen vor der Mülldeponie gerettet.',
        link: 'Der durchschnittliche Plastikanteil eines Kaffeebechers liegt bei 4g.',
      },
      bathtubs: {
        desc: 'Badewannen gefüllt mit Plastikflaschen aus dem meer entfernt.',
        link: 'Die größte Victoria Plumb-Badewanne hat ein Kapazität von 260l, daher würden ungefähr 520 x 500-ml-Flaschen benötigt, um eine zu füllen.',
      },
    },
    carbon: {
      flights: {
        desc: 'Hin- und Rückflüge von London nach Los Angeles.',
        link: 'Im Durchschnitt verursacht eine Hin- und Rückfahrt 932,7 kg CO2 Emissionen. Diese Berechnung variiert je nach Fluggesellschaft.',
      },
      distance: {
        desc: 'Ausgeglichene km mit einem durchschnittlichen Auto. (Fahrzeuge mit 2-Achsen, 4-Reifen).',
        link: 'Die durchschnittliche Person fährt 21,687 km pro Jahr und stößt dabei 5.43 Tonnen CO2 aus.',
      },
      plantBased: {
        desc: 'Jahre an CO2 Emissionen eingespart durch ein pflanzenbasiertes Leben.',
        link: 'Pflanzenbasiert zu leben spart etwa 0,8 Tonnen CO2 pro Jahr, das ist viermal mehr als durch umfassendes Recycling eingespart wird.',
      },
    },
    kelp: {
      babyWhales: {
        desc: 'mal das Gewicht an gepflanztem Seetang eines Baby-Buckelwals.',
        link: 'Ein Baby-Buckelwal wiegt 2267kg.',
      },
      iceHockeyRinks: {
        desc: 'Hockeyfelder voller Seetang',
        link: 'Eine normale Eishockeybahn in Nordamerika ist 1.486,2 Quadratmeter groß. Dichte Seetangbetten haben 10 Pflanzen pro 10 Quadratmeter (d. h. 1 Seetang pro m²).',
      },
      carbonSequestered: {
        desc: 'Tonnen Potentielle CO2-kompensierung pro Jahr.',
        link: 'Im Durchschnitt bindet ein Hektar Seetang 1.4t CO2 im Jahr.',
      },
    },
  },
  PublicLedger: {
    title: 'Zahlungsregister',
    description:
      'Zertifizierungen und Transparenz sind wichtig. Deshalb, posten wir jedes Quartal alle Zahlungsnachweise, zu denen unsere Finanzierungen beigetragen haben.',
    view: 'Herunterladen',
    transferredImpact: {
      label: 'Übertragene Wirkung',
      title:
        '{name} hat Impact erstellt, bevor sie Greenspark beigetreten sind, und hat es aus einer anderen Quelle zu ihrem Konto hinzugefügt. Unten finden Sie verifizierte Bestätigungsdokumente.',
    },
  },
  PublicCTA: {
    title: 'Schließe dich unserer Mission an und werde zum Klimahelden!',
    list: {
      integrate: 'Unterstütze die weltweit effektivsten Projekte.',
      impact: 'Unkompliziert in dein Unternehmen einbindbar.',
      plans: 'Mach nachhaltiges Handeln zu deinem Erfolgsfaktor.',
      goals: 'Gestalte den optimalen Plan für deine Ziele.',
    },
    action: 'Mach den ersten Schritt',
  },
  PublicGoalsAchievements: {
    title: "{name}'s Nachhaltigkeitsreise",
    description:
      'Wir haben uns mit Greenspark zusammengetan, um unseren positiven Einfluss auf den Planeten und seine Menschen zu verstärken. Das ist unsere bisherige Reise und unsere Ziele für die Zukunft.',
    goals: {
      title: 'Unsere Ziele',
    },
    achievements: {
      title: 'Unsere Erfolge',
    },
  },
  ComparisonCardPublic: {
    million: 'Mio',
    billion: 'Mrd',
  },
  NotFoundPage: {
    title:
      'Unsere Webseite scheint ein eigenes Öko-Abenteuer erlebt zu haben und ist in die digitale Wildnis abgewandert.',
    description:
      'Aber keine Sorge! Genauso wie Sie engagieren wir uns für die Säuberung unserer Ozeane und den Ausgleich von CO2-Emissionen. Wir möchten Ihnen auch dabei helfen, das zu finden, was Sie suchen.',
    button: 'Gehen Sie zur Startseite',
  },
}
