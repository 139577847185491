import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { VueFire, VueFireAuth } from 'vuefire'
import * as Sentry from '@sentry/vue'
import Toast from 'vue-toast-notification'

import vuetify from '@/plugins/vuetify'
import i18n from '@/i18n'
import { firebaseApp } from '@/auth/firebase.js'

import 'vue3-carousel/dist/carousel.css'
import 'vue-toast-notification/dist/theme-bootstrap.css'
import '@/assets/styles/main.css'

import App from './App.vue'
import router from './router'

const app = createApp(App)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENVIRONMENT,
  enabled: ['public-dev.getmads.com', 'public.getgreenspark.com'].includes(
    window.location.hostname,
  ),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost',
    'public-dev.getmads.com',
    'public.getgreenspark.com',
    /^\//,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

app
  .use(vuetify)
  .use(VueFire, {
    firebaseApp,
    modules: [VueFireAuth()],
  })
  .use(createPinia())
  .use(router)
  .use(i18n)
  .use(Toast)
  .mount('#app')
